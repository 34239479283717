import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";

import "@allenai/varnish/dist/theme.css";

export default function Home() {
  return (
    <>
      <Helmet>
        <title>Matt Deitke</title>
        <meta name="viewport" content=""></meta>
      </Helmet>
      <div className="lg:px-48 px-24 mx-auto max-w-5xl pb-12">
        <h3>Email README</h3>
        <p>
          Unfortunately, I receive too many emails to respond to all of them at
          the moment. I’ll likely see it, but I cannot get much done if I
          respond to most of them.
        </p>
        <hr />
        <p>
          <b>UW Undergraduates.</b> I am excited to mentor a handful of
          undergrads at UW. I am looking for people who are committed and make
          research a top priority of theirs. I am broadly interested in doing
          large-scale foundational research in AI and building useful and robust
          models.
        </p>
        <p>We might be a good match if you’re:</p>
        <p>
          <ul>
            <li>
              - Highly motivated. Can dedicate a substantial amount of time each
              week to research.
            </li>
            <li>
              - Pay attention to detail and take ownership in your work. Do you
              take pride in what you produce? Does the idea of crap with your
              name on it deeply disturb you?
            </li>
            <li>- Excited about the potentials of AI.</li>
            <li>
              - Interests align. I’m broadly excited about mentoring two groups
              of people:
            </li>
            <ul>
              <li>
                * AI researchers, with topics including computer vision, deep
                learning, distributed computing, building datasets and
                benchmarks, and building foundation models.
              </li>
              <li>
                * Artists, with topics including web development, iOS
                development, and 3D modeling.
              </li>
            </ul>
          </ul>
        </p>
        <p>
          I am especially open to more junior undergraduate students who may not
          have a lot of experience but are highly motivated to build exciting
          things!
        </p>
        <p>
          If you believe our interests may be aligned, please feel free to email
          me with:
        </p>
        <p>
          <ul>
            <li>- Your CV / Resume</li>
            <li>
              - Something you’ve done that demonstrates your abilities and
              interest
            </li>
            <li>
              - A recent advance in AI, such as a product, research paper, or
              tool that really excites you!
            </li>
          </ul>
        </p>
        <p>
          I have a limited amount of bandwidth, so I will only be able to mentor
          a handful of students at most.
        </p>
        <hr />
        <p
          style={{
            marginBottom: "10px",
          }}
        >
          <b>Joining AI2.</b> AI2, and the PRIOR team, is typically always
          looking to hire exceptional engineers and researchers on a rolling
          basis. If you’re interested in working at AI2 or with me there, either
          as an intern or full-time, consider{" "}
          <a
            href="https://allenai.org/careers?team_ai2=prior#current-openings-ai2"
            target="_blank"
          >
            applying
          </a>
          . If your interests are related to mine, feel free to reach out!
        </p>
      </div>
    </>
  );
}
